<template>
  <div class="cityLocation">
    <city-list @selectedCity="selectedCid"></city-list>
    <div class="areaMap">
        <!-- <city-layer :cname="cname" ></city-layer> -->
      <leaflet-city-layer :cname="cname"  ></leaflet-city-layer>
    </div>
    <div class="swipercontainer">
      <down :imglist="imglist"></down>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import CityList from '@/components/cityreserch/CityList.vue'
// import CityLayer from '@/components/bmap/cityLayer.vue'

import LeafletCityLayer from '@/components/leaflet/leafletCityLayer.vue'
import Down from '@/components/swiper/down.vue'
import { cityLocationImg } from "@/api/cityresearch"
// import VueBMap from 'vue-mapvgl'
export default {
  components: { Breadcrumb, CityList, Down ,LeafletCityLayer},
  data() {
    return {
      imglist: []
    }
  },
  computed: {
    cname: {
      get() {

        let a = this.$store.state.cname
        // alert(a)
        // console.log('a',  this.$store.state.cname,'aend')
        // let a = "苏州市"

        return a
      },
      set(v) {
        this.$store.commit('changeCname', v)
      }
    },
    //当前选择的id
    currentArea: {
      get() {
        return this.$store.state.currentArea
      }
    }
  },
  watch: {
    currentArea(newv) {

      this.cname = this.$store.state.cname

      this.getImgList()

    }
  },
  mounted() {
    this.cname = this.$store.state.cname
    this.getImgList()
  },
  methods: {
    selectedCid(cid) {
      this.$store.state.currentCity.filter(item=>{
        if(item.id === cid){
          this.$store.commit('changeCname',item.label)
        }
      })
    },
    getImgList() {
      cityLocationImg({ city_plate_id: this.currentArea }).then(res => {

        this.imglist = res.data
      })
      // this.imglist = [];

    }

  }
}
</script>
<style lang="scss" scoped>
.cityLocation {
  width: 100%;
  height: calc(100vh - 100px);
  color: #333;
}

.areaMap {
  width: 98%;

  margin: 0 1%;
  height: calc(100vh - 284px);
  background: #ddd;
}

.swipercontainer {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  top: -100%;
  z-index: 10;
}
</style>